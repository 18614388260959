import React from "react"
import Image from "../Images/Images"
import {
  FaFacebookF,
  FaGithub,
  FaLinkedinIn,
  FaResearchgate,
} from "react-icons/fa"

function AuthorsContent(props) {
  return (
    <div
      key={props.index}
      className="card shadow border-primary mb-4"
      style={
        props.activeTabId === props.index
          ? {
              display: "block",
              backgroundColor:
                "#" + Math.random().toString(16).slice(-6) + "10",
            }
          : { display: "none" }
      }
    >
      <div className="card-body">
        <div className="d-flex flex-column align-items-center text-center">
          <Image
            className="shadow rounded-circle mb-2"
            filename={`${props.data.name}.jpg`}
            alt={props.data.name}
            style={{
              width: "160px",
              height: "160px",
              margin: "0 auto",
            }}
          />
          <div className="mt-3">
            <h4 className="fw-bold">{props.data.name}</h4>
            <h5 className="fw-bold">{props.data.profession}</h5>

            <div className="float-middle py-3">
              <a
                href={props.data.facebook}
                className="mx-2 btn bg-primary text-light shadow"
                style={{
                  fontSize: "1.2rem",
                  padding: "0px 5px 5px 5px",
                }}
              >
                <FaFacebookF />
              </a>
              <a
                href={props.data.github}
                className="mx-2 btn bg-primary text-light shadow"
                style={{
                  fontSize: "1.2rem",
                  padding: "0px 5px 5px 5px",
                }}
              >
                <FaGithub />
              </a>
              <a
                href={props.data.researchgate}
                className="mx-2 btn bg-primary text-light shadow"
                style={{
                  fontSize: "1.2rem",
                  padding: "0px 5px 5px 5px",
                }}
              >
                <FaResearchgate />
              </a>
              <a
                href={props.data.linkedin}
                className="mx-2 btn bg-primary text-light shadow"
                style={{
                  fontSize: "1.2rem",
                  padding: "0px 5px 5px 5px",
                }}
              >
                <FaLinkedinIn />
              </a>
            </div>

            <p className="text-muted font-size-sm">{props.data.biography}</p>
            <button className="btn btn-outline-primary shadow m-2">
              Follow
            </button>
            <button className="btn btn-outline-primary shadow m-2">
              Message
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthorsContent
